import React from 'react';
import { LocalFilters, SelectEventType } from 'src/pages/DashboardPage/Filters/types';
import { ListSelector } from './ListSelector';
import { SearchField } from './SearchField';
import { Spacer } from 'src/common';
import _ from 'lodash';

interface GenericListProps {
  type: SelectEventType;
  filters: LocalFilters[keyof LocalFilters];
  handleOnSelect: (tag: string, type: SelectEventType) => void;
  handleOnSelectAll?: (isAdd: boolean, type: SelectEventType) => void;
  searchString: string;
  setSearchString: (value: string) => void;
  emptyTextWithSearch: string;
  checkIsYou?: (option: { tag: string; label: string; enabled: boolean }) => boolean;
  prioritySortString?: string;
  showSearchField?: boolean;
}

export const GenericList: React.FC<GenericListProps> = ({
  type,
  filters,
  handleOnSelect,
  handleOnSelectAll,
  searchString,
  setSearchString,
  emptyTextWithSearch,
  checkIsYou,
  prioritySortString = 'no owner',
  showSearchField = true,
}) => {
  const filtered = _.chain(filters)
    .filter((el) => el.label.toLowerCase().includes(searchString.toLowerCase()))
    .map((el) => {
      const cleanLabel = el.label.replace(/\([^)]+@[^\s)]+\)/, '').trim();
      return { ...el, label: cleanLabel === 'No Owner' ? '[Blank]' : cleanLabel };
    })
    .orderBy(
      [(el) => (el.label.toLowerCase() === prioritySortString ? 0 : 1), (el) => el.label.toLowerCase()],
      ['asc', 'asc'],
    )
    .value();

  return (
    <>
      {showSearchField && (
        <>
          <SearchField onChange={(e) => setSearchString(e.target.value)} value={searchString} />
          <Spacer $px={16} />
        </>
      )}
      <ListSelector
        data={filtered}
        selected={filtered.filter((el) => el.enabled).map((el) => el.tag)}
        onSelectAll={handleOnSelectAll ? (isAdd) => handleOnSelectAll(isAdd, type) : undefined}
        onSelect={(tag) => handleOnSelect(tag, type)}
        emptyText={searchString ? 'No results found' : emptyTextWithSearch}
        checkIsYou={checkIsYou}
        allSelectorDisabled={!!searchString}
      />
    </>
  );
};
