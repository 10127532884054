import { ColumnDef, sortingFns } from '@tanstack/react-table';
import React from 'react';
import { Link } from 'react-router-dom';
import { Control } from 'src/api/types';
import GenericTable, { GTColumnSelectAnchor, GTExpander, GTModifierLabel } from 'src/components/GenericTable';
import { GTInternalIds } from 'src/components/GenericTable/types';
import { formatDate, numeralFormat } from 'src/utils/misc';
import { filter } from 'lodash';
import { getIncompleteState } from 'src/common';
import { checkIsModifierStrategy } from 'src/components/Modifier/util';
import useVersion from 'src/hooks/useVersion';
import { encodeQueryParams } from 'src/api/client';
import { TableIds } from 'src/api/types/misc';
import useLocalStorage from 'src/hooks/useLocalStorage';
import { Modifier } from 'src/components/Modifier';
import { convertToModifiers } from 'src/components/ProjectedRisk/util';
import { VariantsScenario } from '../util';
import { OpenInNew } from '@mui/icons-material';

interface ScenarioTableExpandContentProps {
  data: { control: Control; modifiers: any }[];
  scenario: VariantsScenario;
}

const defaultColumns = ['strategy', 'name', 'owner', 'status', 'updated_at', '_mod_applied'];
const internalIds = [GTInternalIds.expander, GTInternalIds.tdm];

export const ScenarioTableExpandContent: React.FC<ScenarioTableExpandContentProps> = ({ data, scenario }) => {
  const [selectedColumns, setSelectedColumns] = useLocalStorage(
    `${TableIds.assessmentScenariosControls}-columns`,
    defaultColumns,
  );
  const { version } = useVersion();

  const columns: ColumnDef<{ control: Control; modifiers: any }>[] = [
    {
      id: GTInternalIds.expander,
      header: () => null,
      cell: ({ row }) => {
        return row.getCanExpand() ? (
          <GTExpander onClick={row.getToggleExpandedHandler()} isExpanded={row.getIsExpanded()} />
        ) : null;
      },
      size: 1,
      enableSorting: false,
    },
    {
      id: 'strategy',
      header: 'Strategy',
      accessorFn: (row) => row.control.strategy || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'name',
      header: 'Control',
      accessorFn: (row) => row.control.name,
      cell: (info) => (
        <Link
          className="table-link"
          // @ts-ignore
          to={`/risk-management/control/${info.row.original.control.ux_id}${
            version
              ? encodeQueryParams({
                  version,
                  assessmentId: scenario.assessment_id,
                })
              : ''
          }`}
        >
          {info.getValue() as string}
        </Link>
      ),
      sortingFn: sortingFns.text,
      maxSize: 300,
    },
    {
      id: 'owner',
      header: 'Owner',
      accessorFn: (row) => row.control.owner?.text || null,
      cell: (info) => info.getValue(),
      sortingFn: sortingFns.text,
    },
    {
      id: 'frameworkLibrary_shortname',
      header: 'Framework',
      accessorFn: (row) => row.control.frameworkLibrary_shortname || null,
      cell: (info) => (
        <Link className="table-link" to={`/control-frameworks/${info.row.original.control.frameworkLibrary_ux_id}`}>
          {info.getValue() as string}
        </Link>
      ),
      sortingFn: sortingFns.text,
    },
    {
      id: 'controlId',
      header: 'Framework ID',
      accessorFn: (row) => row.control.controlId || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'annual_cost',
      header: 'Annual Cost',
      accessorFn: (row) => row.control.annual_cost || null,
      cell: ({ row }) => numeralFormat(row.original.control.annual_cost),
      sortingFn: sortingFns.alphanumeric,
    },
    {
      id: 'status',
      header: 'Status',
      accessorFn: (row) => row.control.status || null,
      cell: (info) => info.getValue(),
    },
    {
      id: 'updated_at',
      header: 'Last Edited',
      accessorFn: (row) => row.control.updated_at || null,
      cell: ({ row }) => formatDate(row.original.control.updated_at, { formatType: 'datetime' }),
    },
    {
      id: '_mod_applied',
      header: 'Modifiers',
      accessorFn: (row) => row.modifiers?.length || 0,
      cell: (info) => {
        const isModStrategy = checkIsModifierStrategy(info.row.original.control.strategy);

        if (!isModStrategy) return null;
        return (
          <GTModifierLabel
            length={info.getValue() as number}
            satisfiesModifier={getIncompleteState(scenario) !== 'TECH'}
            plainText
          />
        );
      },
    },
    {
      id: 'source',
      header: 'Link',
      accessorFn: (row) => row.control.source || null,
      cell: (info) => {
        const url = info.getValue();
        const linkText = (info.row.original.control.url_name || url) as string;

        return url ? (
          <a
            href={url as string}
            target="_blank"
            rel="noopener noreferrer"
            className="table-link"
            onClick={(e) => e.stopPropagation()}
          >
            {linkText}{' '}
            <OpenInNew
              css={`
                font-size: 14px !important;
                color: inherit;
              `}
            />
          </a>
        ) : (
          <span>{linkText}</span>
        );
      },
    },
    {
      id: GTInternalIds.tdm,
      header: () => null,
      cell: () => null,
      size: 1,
    },
    // {
    //   id: '_mod_applied',
    //   header: 'Modifiers Applied',
    //   accessorFn: (row) => (row.modifiers?.treat.length || 0) + (row.modifiers?.transfer.length || 0),
    //   cell: (info) => {
    //     console.log(info.row.original);
    //     const length = info.getValue();
    //     return `${length} Modifier${length === 1 ? '' : 's'}`;
    //   },
    // },
  ];

  return (
    <GenericTable
      tableId={TableIds.assessmentScenariosControls}
      data={data}
      columns={filter(columns, (el) => [...internalIds, ...selectedColumns].includes(el.id as string))}
      GTColumnSelectAnchorExported={
        <GTColumnSelectAnchor
          options={[
            { key: 'strategy', title: 'Strategy' },
            { key: 'name', title: 'Control' },
            { key: 'owner', title: 'Owner' },
            { key: 'frameworkLibrary_shortname', title: 'Framework' },
            { key: 'controlId', title: 'Framework ID' },
            { key: 'annual_cost', title: 'Annual Cost' },
            { key: 'status', title: 'Status' },
            { key: 'updated_at', title: 'Last Edited' },
            { key: '_mod_applied', title: 'Modifiers' },
            { key: 'source', title: 'Link' },
          ]}
          defaultOptions={defaultColumns}
          value={selectedColumns}
          onChange={setSelectedColumns}
        />
      }
      expandContent={(el) => {
        const modsArr =
          // @ts-ignore
          el.modifiers?.map((el) => {
            return { ...el, data: JSON.parse(el.data) };
          }) || [];

        if (!modsArr.length) return null;
        const modifiers = convertToModifiers(modsArr);
        return <Modifier control={{ ...el.control, modifiers }} projectedScenario={scenario} disabled noResult />;
      }}
      darkMode
    />
  );
};
